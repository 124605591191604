import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeManager from "./middlewares/ThemeManager";
import PageDashboard from "./pages/dashboard";
import PageSplashScreen from "./components/SplashScreen";
import AuthenticatedRoute from "./middlewares/AuthenticatedRoute";
import PageLogin from "./pages/login";
import PageSignup from "./pages/signup";
import PageRecovery from "./pages/recovery";
import PageUpdatePassword from "./pages/update-password";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import PageVerification from "./pages/verify";
import AuthHandler from "./pages/auth";
import ModalProvider from "mui-modal-provider";
import LandingPage from "./pages/landing";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { motion } from "framer-motion";
import TermsPage from "./pages/landing/terms";
import PrivacyPage from "./pages/landing/privacy";
import AffiliatesPage from "./pages/landing/affiliate";
import ErrorPage from "./pages/404";
import ReactPixel from "react-facebook-pixel";
import InvoicePage from "./pages/invoice";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

ReactPixel.init("7950393438375690", undefined, {
  autoConfig: true,
  debug: false,
});

root.render(
  <RecoilRoot>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* The theme provider is added at the root level  */}
      <ThemeManager>
        <ModalProvider>
          <SnackbarProvider
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <PageSplashScreen>
                <BrowserRouter basename="/">
                  <Routes>
                    <Route
                      path="/signup"
                      element={
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <PageSignup />
                        </motion.div>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <PageLogin />
                        </motion.div>
                      }
                    />
                    <Route
                      path="/recovery"
                      element={
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <PageRecovery />
                        </motion.div>
                      }
                    />
                    <Route path="/verify" element={<PageVerification />} />
                    <Route path="/auth" element={<AuthHandler />} />
                    <Route
                      path="/update-password"
                      element={<PageUpdatePassword />}
                    />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/partner" element={<AffiliatesPage />} />
                    <Route path="/" element={<LandingPage />} />

                    <Route
                      path="/admin/*"
                      element={
                        <AuthenticatedRoute redirect={"/login"}>
                          <PageDashboard />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route
                      path="/user/*"
                      element={
                        <AuthenticatedRoute redirect={"/login"}>
                          <PageDashboard />
                        </AuthenticatedRoute>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <AuthenticatedRoute redirect={"/login"}>
                          <PageDashboard />
                        </AuthenticatedRoute>
                      }
                    />

                    <Route path="/invoice/:id" element={<InvoicePage />} />
                    <Route path="/*" element={<ErrorPage />} />
                  </Routes>
                </BrowserRouter>
              </PageSplashScreen>
            </motion.div>
          </SnackbarProvider>
        </ModalProvider>
      </ThemeManager>
    </LocalizationProvider>
  </RecoilRoot>
);
