import React, { useEffect, useState } from "react";
import { Paper, Stack, SvgIcon, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { aCurrentSection, aCurrentSectionDescription } from "../../core/states";
import { IC_AFFILIATE_BALANCE, IC_PAID, IC_PENDING } from "../../assets/ui";

import { enqueueSnackbar } from "notistack";
import { motion } from "framer-motion";

export default function SectionAdminPayouts() {
  const [busy, setBusy] = useState(false);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);

  async function load(page: number) {
    try {
      setBusy(true);
    } catch (err: any) {
      console.error("Error loading payouts data. ", err);
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Payouts`);
    setSectionDescription(`View and manage pending and completed payouts.`);
    load(0);
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, translateX: -20 }}
        whileInView={{ opacity: 1, translateX: 0 }}
        exit={{ opacity: 0, translateX: 20 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            p: "12px",
            height: "100%",
            transition: "all 1s",
          }}
          spacing={"32px"}
        >
          <Stack
            direction={"row"}
            rowGap={"24px"}
            columnGap={"24px"}
            flexWrap={"wrap"}
          >
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.1 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  transition: "all .3s",
                  border: "1px solid #FFF0",
                  ":hover": {
                    border: "1px solid #48FA",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#48F",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_AFFILIATE_BALANCE />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Balances
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.2 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  border: "1px solid #FFF0",
                  borderRadius: "8px",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #448A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#448",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PENDING />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Pending
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
            <motion.div
              initial={{ scale: 0.8 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.1, delay: 0.3 }}
              exit={{ scale: 0 }}
            >
              <Paper
                sx={{
                  minWidth: "250px",
                  background: "#FFF1",
                  borderRadius: "8px",
                  border: "1px solid #FFF0",
                  transition: "all .3s",
                  ":hover": {
                    border: "1px solid #0A5A",
                  },
                }}
              >
                <Stack sx={{ p: "24px" }} spacing={"24px"}>
                  <Stack
                    direction={"row"}
                    spacing={"18px"}
                    alignItems={"center"}
                  >
                    <SvgIcon
                      sx={{
                        background: "#0A5",
                        height: "48px",
                        width: "48px",
                        p: "12px",
                        borderRadius: "8px",
                      }}
                    >
                      <IC_PAID />
                    </SvgIcon>
                    <Typography fontWeight={500} fontSize={20}>
                      Total Paid
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      alignItems={"center"}
                      direction={"row"}
                      spacing={"8px"}
                    >
                      <Typography fontWeight={500} fontSize={24}></Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </motion.div>
          </Stack>
        </Stack>
      </motion.div>
    </>
  );
}
